import { Injectable, signal } from '@angular/core';
import { LoginResponse } from '../models/login-response.model';
import { StorageKeys } from '../models/enums/storage-keys.enum';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  token = signal<LoginResponse | null>(null);
  delegatedToken = signal<LoginResponse | null>(null);

  activeUser = signal<LoginResponse | null>(null);

  setToken(user: LoginResponse) {
    this.token.set(user);
    this.activeUser.set(user);
    sessionStorage.setItem(StorageKeys.USER_TOKEN, JSON.stringify(user));
  }

  getToken() {
    return sessionStorage.getItem(StorageKeys.USER_TOKEN);
  }

  removeToken() {
    this.token.set(null);
    this.delegatedToken.set(null);
    sessionStorage.removeItem(StorageKeys.USER_TOKEN);
    sessionStorage.removeItem(StorageKeys.DELEGATED_USER_TOKEN);
  }

  setDelegatedToken(user: LoginResponse) {
    this.activeUser.set(user);
    this.delegatedToken.set(user);
    sessionStorage.setItem(
      StorageKeys.DELEGATED_USER_TOKEN,
      JSON.stringify(user),
    );
  }

  getDelegatedToken() {
    return sessionStorage.getItem(StorageKeys.DELEGATED_USER_TOKEN);
  }

  getActiveToken() {
    return this.getDelegatedToken() || this.getToken();
  }

  removeDelegatedToken() {
    this.delegatedToken.set(null);

    sessionStorage.removeItem(StorageKeys.DELEGATED_USER_TOKEN);
  }


}
