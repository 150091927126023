import { TokenService } from './token.service';
import { inject, Injectable, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FiltersService } from './filters.service';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationStateService {
  id = signal<number | null>(null);

  filterService = inject(FiltersService);
  tokenService = inject(TokenService);
  loaderService = inject(LoaderService);

  projectId$ = toObservable(this.id);

  setProjectId(id: number | null) {
    if (this.id() !== id && this.tokenService.getToken()) {
      this.loaderService.startLoading();
      this.filterService._RESET();
      this.filterService.isLoaded.set(false);
      this.filterService
        .getAllfilters(id!)
        .subscribe(() => this.loaderService.stopLoading());
    }
    this.id.set(id);
  }
}
