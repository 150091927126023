import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable, forkJoin, map, tap } from 'rxjs';
import { FilterActor, FilterLineage, FilterNature } from '../models/filters';
import { TableFilter } from '../models/table-filter';
import { Field, Way } from '../models/enums/order-field';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  readonly #URL = `${environment.apiUrl}/projects`;
  readonly #http = inject(HttpClient);

  activeLineage = signal<number[]>([]);
  activeFilter$$ = signal<{ id: number; name: string; type: string }[]>([]);

  private RESET_TABLE_FILTER: TableFilter = {
    filters: {
      lignees: [],
      phases: [],
      pilots: [],
      natures: [],
      gravities: [],
      status: [],
    },
    order: {
      field: 'date_butoir',
      way: Way.asc,
    },
  };

  isLoaded = signal<boolean>(false);

  visiblity = signal<boolean>(false);

  actorFilter = signal<FilterActor>([]);
  lineageFilter = signal<FilterLineage>([]);
  natureFilter = signal<FilterNature>([]);
  statusFilter = signal<number[]>([]);
  severityFilter = signal<number[]>([]);

  handler() {
    this.visiblity.set(!this.visiblity());
  }

  setPilots(pilots: number[]) {
    if (this.filterParams.filters) {
      this.filterParams.filters.pilots = pilots;
      this.filterParamsSubject.next(this.filterParams);
    }
  }

  setLineages(lineages: number[]) {
    if (this.filterParams.filters) {
      this.filterParams.filters.lignees = lineages;
      this.filterParamsSubject.next(this.filterParams);
    }
  }

  setNatures(natures: number[]) {
    if (this.filterParams.filters) {
      this.filterParams.filters.natures = natures;
      this.filterParamsSubject.next(this.filterParams);
    }
  }

  setGravities(gravities: number[]) {
    if (this.filterParams.filters) {
      this.filterParams.filters.gravities = gravities;
      this.filterParamsSubject.next(this.filterParams);
    }
  }

  setOrderField(field: Field) {
    if (
      this.filterParams &&
      this.filterParams.order &&
      this.filterParams.order.field === field
    ) {
      this.filterParams.order.way =
        this.filterParams.order.way === Way.asc ? Way.desc : Way.asc;
    } else {
      this.filterParams.order = {
        field: field,
        way: Way.asc,
      };
    }
    this.filterParamsSubject.next(this.filterParams);
  }

  setStatus(status: number[]) {
    if (this.filterParams.filters) {
      this.filterParams.filters.status = status;
      this.filterParamsSubject.next(this.filterParams);
    }
  }

  private filterParamsSubject = new BehaviorSubject<TableFilter>(
    this.RESET_TABLE_FILTER,
  );

  filterParams$ = this.filterParamsSubject.asObservable();

  reloadWithSameParams() {
    this.filterParamsSubject.next(this.filterParams);
  }

  get filterParams() {
    return this.filterParamsSubject.value;
  }

  get activeOrderField() {
    return this.filterParamsSubject.value.order;
  }

  _RESET() {
    this.activeFilter$$.set([]);
    this.filterParamsSubject.next(this.RESET_TABLE_FILTER);
  }

  getActors(id: number): Observable<FilterActor> {
    return this.#http.get<any>(`${this.#URL}/${id}/actors/filter`).pipe(
      map((e) => e.data),
      tap((data) => {
        this.actorFilter.set(data);
      }),
    );
  }

  getLineage(id: number): Observable<FilterLineage> {
    return this.#http.get<any>(`${this.#URL}/${id}/lignees`).pipe(
      map((e) => e.data),
      tap((data) => {
        this.lineageFilter.set(data);
      }),
    );
  }

  getNature(id: number): Observable<FilterNature> {
    return this.#http.get<any>(`${this.#URL}/${id}/natures`).pipe(
      map((e) => e.data),
      tap((data) => {
        this.natureFilter.set(data);
      }),
    );
  }

  getStatus(id: number): Observable<number[]> {
    return this.#http.get<any>(`${this.#URL}/${id}/status`).pipe(
      map((e) => e.data),
      tap((data) => {
        this.statusFilter.set(data);
      }),
    );
  }

  getSeverity(id: number): Observable<number[]> {
    return this.#http.get<any>(`${this.#URL}/${id}/gravities`).pipe(
      map((e) => e.data),
      tap((data) => {
        this.severityFilter.set(data);
      }),
    );
  }

  getAllfilters(id: number) {
    return forkJoin([
      this.getActors(id),
      this.getLineage(id),
      this.getNature(id),
      this.getStatus(id),
      this.getSeverity(id),
    ]).pipe(
      tap(() => {
        this.isLoaded.set(true);
      }),
    );
  }
}
